import { firestore } from '../client-packages/firebase';
import { collection, deleteDoc, doc, getDoc, getDocs, setDoc, updateDoc } from 'firebase/firestore';
import { AuthService } from './auth.service';
export class UserService {
    static async create(user) {
        const userCol = collection(firestore, 'users');
        const newUserDoc = doc(userCol, user.id);
        const userData = {
            name: user.name,
            email: user.email,
            role: user.role,
            createdAt: user.createdAt
        };
        try {
            await setDoc(newUserDoc, userData);
            return user;
        }
        catch (e) {
            const msg = 'Error by creating user in Firestore: ' + e;
            console.error(msg);
            throw new Error(msg);
        }
    }
    static async getUser(id) {
        const docRef = doc(firestore, 'users', id);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            return docSnap.data();
        }
        else {
            console.error('No such document!');
            return undefined;
        }
    }
    static async getAllUser() {
        const usersRef = collection(firestore, 'users');
        const snapshot = await getDocs(usersRef);
        const users = UserService.getDataFromSnapshot(snapshot);
        if (users.length > 0) {
            return users;
        }
        else {
            console.error('No users found!');
            return undefined;
        }
    }
    static async updateUser(user) {
        const docRef = doc(firestore, 'users', user.id);
        await updateDoc(docRef, user);
    }
    static async deleteUser(userId) {
        const userRef = doc(firestore, 'users/' + userId);
        try {
            await deleteDoc(userRef);
            await AuthService.deleteUser();
        }
        catch (e) {
            throw new Error('Error by deleting user! ' + e);
        }
    }
    static getDataFromSnapshot(snapshot) {
        return snapshot.docs.map(value => {
            return { ...value.data(), id: value.id };
        });
    }
}
