import { reducer } from './reducer';
import { createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
export const INITIAL_STATE = {
    loggedIn: false,
    user: undefined,
    events: [],
    rooms: []
};
export const store = createStore(reducer, composeWithDevTools());
