var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { customElement, html, LitElement, property } from 'lit-element';
import { PageMixin } from '../../client-packages/page.mixin';
import { EventService } from '../../services/event.service';
import { RoomService } from '../../services/room.service';
import './web-events.scss';
let WebEvents = class WebEvents extends PageMixin(LitElement) {
    constructor() {
        super(...arguments);
        this.events = [];
        this.user = undefined;
    }
    render() {
        return html `
      <div class="">
        <web-calendar></web-calendar>
        <!-- <hr class="my-5"/>
        <div class="mb-3 events-list">
          <h1>Alle Buchungen</h1>

          <div class="table-responsive">
            <table class="table">
              <thead>
                <tr>
                  <th scope="col">Titel</th>
                  <th scope="col">Start</th>
                  <th scope="col">Ende</th>
                  <th scope="col">Raum</th>
                  <th scope="col">Erstellt von</th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                ${this.events.map(event => {
            var _a, _b;
            return html `
                <tr>
                  <th scope="row">${event.title}</th>
                  <td>${this.formateDate(event.start.toDate())}</td>
                  <td>${this.formateDate(event.end.toDate())}</td>
                  <td>${event.room}</td>
                  <td>${event.createdFrom}</td>
                  ${((_a = this.user) === null || _a === void 0 ? void 0 : _a.role) === 'admin' || ((_b = this.user) === null || _b === void 0 ? void 0 : _b.id) === event.createdFromId ? html `
                  <td class="event-actions">
                    <edit-event .event=${event} class="align-self-center me-3"></edit-event>
                    <button type="button" class="btn btn-danger" @click=${() => this.deleteEvent(event.id)}>Löschen</button>
                  </td>
                  ` : undefined}
                </tr>
                `;
        })}
              </tbody>
            </table>
          </div>
        </div> -->
      </div>
        `;
    }
    firstUpdated() {
        this.loadEvents();
        this.loadRooms();
    }
    stateChanged(state) {
        this.user = state.user;
        if (state.events.length >= 0) {
            this.events = state.events.sort((a, b) => {
                if (a.start > b.start) {
                    return 1;
                }
                else if (a.start < b.start) {
                    return -1;
                }
                else {
                    return 0;
                }
            });
        }
    }
    loadEvents() {
        EventService.loadEvents();
    }
    async loadRooms() {
        await RoomService.loadRooms();
    }
    deleteEvent(id) {
        const deleteSingle = confirm('Soll diese Buchung wirklich gelöscht werden?');
        if (id) {
            const deleteFuture = confirm('Sollen ebenfalls alle Zukünftigen Buchungen gelöscht werden?');
            if (deleteFuture) {
                EventService.deleteEvent(id, true);
                return;
            }
        }
        if (deleteSingle == true) {
            EventService.deleteEvent(id);
        }
    }
    formateDate(date) {
        let month = '' + (date.getMonth() + 1), day = '' + date.getDate(), minutes = date.getMinutes().toString();
        const year = date.getFullYear(), hours = date.getHours();
        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;
        if (minutes.length < 2)
            minutes = minutes + '0';
        return [day, month, year].join('.') + ' ' + hours + ':' + minutes + ' Uhr';
    }
};
__decorate([
    property({ attribute: false }),
    __metadata("design:type", Array)
], WebEvents.prototype, "events", void 0);
__decorate([
    property({ attribute: false }),
    __metadata("design:type", Object)
], WebEvents.prototype, "user", void 0);
WebEvents = __decorate([
    customElement('web-events')
], WebEvents);
export default WebEvents;
