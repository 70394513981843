var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Modal } from 'bootstrap';
import { customElement, html, LitElement, property, query } from 'lit-element';
import { PageMixin } from '../../client-packages/page.mixin';
import { router } from '../../client-packages/router';
import { AuthService } from '../../services/auth.service';
import './web-register.scss';
let WebRegister = class WebRegister extends PageMixin(LitElement) {
    constructor() {
        super(...arguments);
        this.error = false;
    }
    render() {
        return html `
      <div class="signin-container">
        <form class="form-signin">
          <h1 class="h3 mb-3 fw-normal">Bitt registriere dich</h1>
  
          <div class="form-floating">
            <input type="text" required class="form-control" id="name" placeholder="Max Mustermann">
            <label for="floatingInput">Vorname Nachname</label>
          </div>
          <div class="form-floating">
            <input type="email" required class="form-control" id="email" placeholder="name@example.com">
            <label for="floatingInput">Email-Adresse</label>
          </div>
          <div class="form-floating">
            <input type="password" required class="form-control" id="password" placeholder="Password">
            <label for="floatingPassword">Passwort</label>
          </div>
          <div class="form-floating">
            <input type="password" required class="form-control" id="password2" placeholder="Password">
            <label for="floatingPassword">Bestätige dein Passwort</label>
          </div>

          <div class="message-box">
            ${this.error ? html `
            <div  class="text-danger"> 
              Login fehlgeschlagen! Haben Sie Email und Passwort richtig eingegeben?
            </div>
            ` : undefined}
          </div>

          <button class="w-100 btn btn-lg btn-primary" type="submit" @click=${this.submit}>Registrieren</button>

          <div class="form-text">Du hast bereits einen Account? <button type="button" class="btn btn-link" @click=${() => router.navigate('login')}>Log dich ein</button></div>
          <p class="mt-5 mb-3 text-muted">&copy; 2021</p>
        </form>
      </div>


      <!-- Modal -->
      <div class="modal fade" id="registerModal" tabindex="-1" aria-labelledby="registerModalLabel" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="registerModalLabel">Account Aktivierung</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              Bitte benachrichtigen Sie einen Admin, um ihren Account zu aktivieren. (z.B. Jonathan Hartmann)
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-primary" data-bs-dismiss="modal">Ok</button>
            </div>
          </div>
        </div>
      </div>
        `;
    }
    async submit(event) {
        event.preventDefault();
        const password1 = this.passwordInput.value;
        const password2 = this.password2Input.value;
        if (this.form.reportValidity() && (password1 === password2)) {
            try {
                await AuthService.register(this.emailInput.value, this.passwordInput.value, this.nameInput.value);
                const element = document.getElementById('registerModal');
                if (element) {
                    const registerModal = new Modal(element);
                    registerModal.show();
                }
            }
            catch (error) {
                console.error(error);
                this.error = true;
                this.requestUpdate();
            }
        }
    }
};
__decorate([
    query('form'),
    __metadata("design:type", HTMLFormElement)
], WebRegister.prototype, "form", void 0);
__decorate([
    query('#name'),
    __metadata("design:type", HTMLInputElement)
], WebRegister.prototype, "nameInput", void 0);
__decorate([
    query('#email'),
    __metadata("design:type", HTMLInputElement)
], WebRegister.prototype, "emailInput", void 0);
__decorate([
    query('#password'),
    __metadata("design:type", HTMLInputElement)
], WebRegister.prototype, "passwordInput", void 0);
__decorate([
    query('#password2'),
    __metadata("design:type", HTMLInputElement)
], WebRegister.prototype, "password2Input", void 0);
__decorate([
    property({ type: Boolean }),
    __metadata("design:type", Object)
], WebRegister.prototype, "error", void 0);
WebRegister = __decorate([
    customElement('web-register')
], WebRegister);
export default WebRegister;
